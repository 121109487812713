export const environment = {
  name: 'stg',
  userPoolId: 'ap-south-1_uiJanthWm',
  userPoolClientId: 'g338ghbj5pi4gkls3fnvim7dk',
  cognitoDomain: 'stg-rewaa-admin.auth.ap-south-1.amazoncognito.com',
  scopes: [
    'phone',
    'email',
    'openid',
    'profile',
    'aws.cognito.signin.user.admin',
  ],
  redirectSignIn: [`${window.location.origin}/accounts`],
  redirectSignOut: [`${window.location.origin}/login`],
  responseType: 'token',
  launchDarklyClientSideId: '63566d3ef1d7751181713514',
  crmAccountUrl: 'https://crmsandbox.zoho.com/crm/newstaging/tab/Accounts/',
  chargebeeAccountUrl: 'https://rewaa-test.chargebee.com/d/customers/',
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  appVersion: require('../../package.json').version,
  enableDatadog: false,
  enableFullStory: false,
  datadogApplicationId: 'dea2a987-fdaa-49b3-aff9-b1840fe4b2bc',
  datadogClientToken: 'pub4e8cd88ba279a0885c9643c29c611449',
};
